export default {
  async insert (Vue, idoperacioncrm, idempleado, formData, ) {
    const resp = await Vue.$api.call(
      'contactoCliente.insert',
      {
        values: {
          idempleado: idempleado,
          idoperacioncrm: idoperacioncrm,
          descripcion: formData.descripcion,
          observaciones: formData.observaciones,
          idtcontacto: formData.idtcontacto,
          idcanal_comunicacion: formData.idcanal_comunicacion,
          fcontacto: formData.fcontacto,
        },
      },
    )
    return resp.data.result.dataset[0]
  },
}
